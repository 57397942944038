:root {
    --color-light-green: #488f31;
    --color-light-red: #de425b;
    --color-selection-box: #62b1ff;
    --box-border-color: #ccc;
    --color-blue: #056d8e;
    --blueish-white: #cee8ea;
  }
  .controls {
    margin-bottom: 20px;
  }
  
  .controls label {
    margin-right: 20px;
  }
  
  .controls input[type="range"] {
    margin: 0 10px;
  }
  
  .controls button {
    margin-top: 10px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    background-color: #282c34;
    color: white;
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .controls button:hover {
    background-color: #61dafb;
  }
  
  .table-container {
    overflow: auto; /* Allow both horizontal and vertical scrolling */
    max-width: 100%; /* Ensure it doesn't overflow the viewport width */
    border: 1px solid #ccc; /* Optional: Add a border for better visibility */
  }
  
  .green {
    fill: var(--color-light-green);
    stroke: var(--box-border-color);
    stroke-width: 1;
  }
  
  .red {
    fill: var(--color-light-red);
    stroke: var(--box-border-color);
    stroke-width: 1;
  }

  .red {
    fill: var(--color-light-red);
    stroke: var(--box-border-color);
    stroke-width: 1;
  }

  .white {
    fill: var(--blueish-white);
    stroke: var(--box-border-color);
    stroke-width: 1;
  }
  
  .blue {
    fill: var(--color-blue);
    stroke: var(--box-border-color);
    stroke-width: 1;
  }

  .event-probability {
    color: #de425b; /* Light blue */
  }
  
  .working-probability {
    color: #056d8e; /* Light red */
  }

  .details-table-container {
    overflow: auto; /* Allow both horizontal and vertical scrolling */
    max-width: 100%; /* Ensure it doesn't overflow the viewport width */
    margin-top: 20px; /* Add space between the two tables */
    margin-left: 20px;
    text-align: left; /* Align the second table to the left */
  }
  
  .equation {
    overflow: auto; /* Allow both horizontal and vertical scrolling */
    max-width: 100%; /* Ensure it doesn't overflow the viewport width */
  }