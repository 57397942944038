.dropdown {
    margin-bottom: 20px;
    text-align: right;
  }
  
  .dropdown-button {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    background-color: #282c34;
    color: white;
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .dropdown-button:hover {
    background-color: #61dafb;
  }
  
  .dropdown-content {
    margin-top: 10px;
  }
  
  .controls button {
    margin-top: 10px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    background-color: #282c34;
    color: white;
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .controls button:hover {
    background-color: #61dafb;
  }
  
  .controls label {
    display: block;
    margin-bottom: 10px;
  }