.h1 {
  text-align: center;
}

.center-text {
  text-align: center;
}

main {
  padding: 80px 20px 20px; /* Add padding to avoid overlap with the fixed navbar */
}

section {
  min-height: 100vh;
}

.controls {
  margin-bottom: 20px;
}

.controls label {
  margin-right: 20px;
}

.controls input[type="range"] {
  margin: 0 10px;
}

.chart {
  overflow: auto; /* Allow both horizontal and vertical scrolling */
  max-width: 100%; /* Ensure it doesn't overflow the viewport width */
  margin-top: 20px;
  text-align: left; /* Align the second table to the left */
}

.equation {
  overflow: auto; /* Allow both horizontal and vertical scrolling */
  max-width: 100%; /* Ensure it doesn't overflow the viewport width */
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 1000ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 1000ms;
}


.chart-container {
  display: flex;
  flex-direction: column;
  height: 50vh;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.chart-wrapper {
  width: 100%;
  height: 0;
  flex-grow: 1;
  position: relative;
  padding-bottom: 0%;
}

.chart-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}